<div class="container-fluid">
  <div class="d-flex">
    <!-- Sidebar -->
    <div class="sidebar-width" id="mobile-nav">
      <app-sidebar></app-sidebar>
    </div>
    
    <!-- Main Content -->
    <div class="flex-grow-1 resp-layout">
      <nav class="navbar navbar-default pt-4 pb-4">
        <!-- Toggler Button -->
        <button 
          class="navbar-toggler open-toggler me-3" 
          (click)="toggleSidebar()" 
          type="button" 
          id="close-btn"
        >
          <i class="bi bi-list"></i>
        </button>
        
        <!-- Welcome Message -->
        <div class="d-flex align-items-center wellcome" style="flex-grow: 1;">
          <h1 class="display-5 fw-bold wellcome">Welcome back, {{ userName }} 👋</h1>
        </div>
        
        <!-- Total Amount -->
        <div class="d-flex align-items-center total-amount ms-auto">
          <small class="text-muted me-1" style="font-size: 18px;">Total Amount:</small>
          <small class="fw-bold text-success" style="font-size: 18px;">
            £{{ totalTopupAmount }}
          </small>
        </div>
        
        <!-- Header Dropdown -->
        <div class="header-icon dropdown ms-3">
          <i class="bi bi-three-dots-vertical custom-icon  p-2 mt-n2" id="headerDropdown" data-bs-toggle="dropdown"
            aria-expanded="false"></i>

            <ul class="dropdown-menu border-secondary bg-light" aria-labelledby="headerDropdown">
              <li>
                <a [routerLink]="['/dashboard']" class="dropdown-item text-black">Dashboard</a>
              </li>
              <li>
                <a class="dropdown-item text-black" (click)="logout()">Logout</a>
              </li>
            </ul>
        </div>
      </nav>
      
      <!-- Page Body -->
      <div class="page-body mt-0 pt-1 pb-0">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
