import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-layout',

  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss'
})
export class DashboardLayoutComponent {
 
 constructor() {

  
 }
 toggleSidebar() {
  const mobileNav = document.getElementById('mobile-nav');
  const closebtn = document.getElementById('close-btn');
  if (mobileNav) {
    mobileNav.classList.toggle('show-nav'); 
    closebtn.classList.toggle('closebtn'); 
    closebtn.classList.toggle('open-toggler'); 
  }
}
}
