import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  activeSubmenu: string = localStorage.getItem('activeSubmenu') || 'quickAccess';
  isSidebarOpen: boolean = false;
  isSubmenuOpen = false;

  constructor(private authService: AuthenticationService, private router: Router) {}

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  toggleSubmenu(submenu: string) {
    this.isSubmenuOpen = !this.isSubmenuOpen;
    this.activeSubmenu = this.activeSubmenu === submenu ? null : submenu;
    localStorage.setItem('activeSubmenu', this.activeSubmenu); // Store the active submenu
  }

  logout() {
    this.authService.logout();
    this.activeSubmenu = 'quickAccess'; // Reset to default submenu
    localStorage.setItem('activeSubmenu', this.activeSubmenu); // Store the default submenu
  }

  isActive(route: string): boolean {
    return this.router.url === route;
  }
}
