import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { RoleType } from '../../constants/constants';
import { TokenService } from '../services/token.service';
import { AuthConfig } from '../auth.config';
import { AuthenticationService } from '../services/authentication.service'; // Import AuthenticationService

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private config: AuthConfig,
    private tokenService: TokenService,
    private router: Router,
    private authService: AuthenticationService // Inject AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expectedRoles: Array<RoleType> = route.data['expectedRoles'];
    return this.checkUser(expectedRoles, state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    const expectedRoles: Array<RoleType> = route.data!['expectedRoles'];
    return this.checkUser(expectedRoles, url);
  }

  private checkUser(expectedRoles: Array<RoleType>, url: string): boolean {
    if (this.authService.isLoggedIn()) { // Use the isLoggedIn method
      const token = this.tokenService.getToken();
      if (token && token.token) {
        const decodeToken = token.decodeToken();
        const tokenRole: RoleType = RoleType[<string>decodeToken.role];
        if (expectedRoles.includes(tokenRole)) {
          return true;
        }
      }
    } else {
      this.router.navigate(['/auth/sign-in'], { queryParams: { returnUrl: url } });
    }

    return false;
  }
}
