<div class="container-fluid">
    <div class="d-flex">
        <div class="sidebar-width" id="mobile-nav">
            <app-sidebar></app-sidebar>
        </div>
        <div class="flex-grow-1 resp-layout">
            <nav class="navbar navbar-default" >
                <button class="navbar-toggler open-toggler" (click)="toggleSidebar()" type="button" id="close-btn">
                  <i class="bi bi-list"></i>
                </button>
                <div class="header-icon">
                  <i class="bi bi-exclamation-circle"></i>
                </div>
              </nav>
            <div class="page-body">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
