import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IUser } from 'src/types';

@Injectable({ providedIn: 'root' })

export class CurrentUserService implements OnInit {
  private url = `${environment.apiUrl}api/userInfo`;

  private observable!: Observable<any>;
  private user!: IUser;
  public balanceSubject = new Subject<boolean>();

  public subject: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) { }

  ngOnInit() { }

  getCurrentUser(): Observable<any> {
    if (this.user != null) {
      this.subject.next(this.user);
      return of(this.user);
    } else if (this.observable) {
      return this.observable;
    } else {
      this.observable = this.http.get(`${this.url}/myProfile`).pipe(
        tap<any>((user: IUser) => {
          if (user) {
            this.user = user;
            this.observable = null as any;
            this.subject.next(this.user);
            return of(this.user);
          }
        }));
      return this.observable;
    }
  }

  getUserEmail(userId: string): Observable<any> {
    return this.http.get<any>(`${this.url}/user-email/${userId}`);
  }

  public checkFeatures(features: string[]): Observable<any> {
    if (this.user && this.user.balance) {
      var resp: any = {};
      features.forEach(feature => {
        let feat = feature.split('_');
        resp[feature] = this.featureEnabled(this.user, feat[0], feat[1]);
      });

      return of(resp);
    }

    this.getCurrentUser().subscribe((user: any) => {
      var resp: any = {};
      features.forEach(feature => {
        let feat = feature.split('_');
        resp[feature] = this.featureEnabled(this.user, feat[0], feat[1]);
      });

      this.balanceSubject.next(resp);
    });

    return this.balanceSubject;
  }

  public checkBalance(feature: string, title?: string): Observable<boolean> {
    if (this.user && this.user.balance) {
      return of(this.featureEnabled(this.user, feature, title));
    }

    this.getCurrentUser().subscribe((user: any) => {
      let enabled = this.featureEnabled(user, feature, title);
      this.balanceSubject.next(enabled);
    });

    return this.balanceSubject;
  }

  private featureEnabled(user: any, feature: string, title?: string) {
    let detail = user.balance[feature];
    if (!detail) {
      return false;
    }

    if (title) {
      return detail.enabled && detail.title && detail.title.toLowerCase().includes(title.toLowerCase());
    }
    else {
      if (detail.enabled && detail.items) {
        return detail.items > 0;
      }
      else {
        return detail.enabled;
      }
    }
  }

  //feature enabled with title
  // public checkBalanceByTitle(feature: string, title: string): Observable<boolean> {
  //   if (this.user && this.user.balance) {
  //     return of(this.featureTitleEnabled(this.user, feature, title));
  //   }
  //   // let obsr! : Observable<boolean>;
  //   this.getCurrentUser().subscribe((user: any) => {
  //     let enabled = this.featureTitleEnabled(user, feature, title);
  //     this.balanceSubject.next(enabled);
  //   });

  //   return this.balanceSubject;
  // }


  // private featureTitleEnabled(user: any, feature: string, title: string) {
  //   let detail = user.balance[feature];
  //   if (!detail) {
  //     return false;
  //   }

  //   return detail.enabled && detail.title && detail.title.includes(title);
  // }

  clearCurrentUser() {
    this.user = null as any;
  }
}
