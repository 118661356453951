import { BehaviorSubject, Observable } from 'rxjs';
import { Token } from '../models/token';

// @Injectable()
export class TokenService {

  private _token: BehaviorSubject<Token>;
  private _refreshToken: BehaviorSubject<string>;

  constructor() {
    this._token = <BehaviorSubject<Token>>new BehaviorSubject(new Token(sessionStorage.getItem('auth_token') as any));
    this._refreshToken = <BehaviorSubject<string>>new BehaviorSubject(sessionStorage.getItem('refresh_token'));
  }

  /**
   * Get the current token.
   */
  getToken(): Token {
    const token = this._token.getValue();
    return (token && token.token) ? token : null as any;
  }

  /**
   * Get the current token.
   */
  getRefreshToken(): string {
    return this._refreshToken.getValue();
  }

  /**
   * Returns an stream of tokens.
   */
  getTokenStream(): Observable<Token> {
    return this._token.asObservable();
  }

  /**
 * Update the current token.
 */
  setOTPStatus(val: any) {
    sessionStorage.setItem('auth_otp', val);
  }
  /**
   * Update the current token.
   */
  setToken(token: string) {
    this._token.next(new Token(token));
    sessionStorage.setItem('auth_token', token);
  }

  /**
    * Update the refresh token.
    */
  setRefreshToken(token: string) {
    this._refreshToken.next(token);
    sessionStorage.setItem('refresh_token', token);
  }

  /**
   * Remove the current token.
   */
  removeTokens() {
    this._refreshToken.next(null as any);
    sessionStorage.removeItem('refresh_token');
    this._token.next(null as any);
    sessionStorage.removeItem('auth_token');
    sessionStorage.removeItem('auth_otp');
  }

}
