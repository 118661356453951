import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { AuthConfig } from '../auth.config';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpUrlEncodingCodec } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { map, catchError, finalize, filter } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CurrentUserService } from 'src/app/shared/Services/current-user.service';


@Injectable()
export class AuthenticationService {
  private sessionTimeoutDuration = 30 * 60 * 1000; // 30 minutes
  private sessionTimeout: any;
  private userSubject: BehaviorSubject<any | null>;
  public user: Observable<any | null>;
  private postUrl = `${environment.apiUrl}api/Auth`;
  constructor(private http: HttpClient,
    private authConfig: AuthConfig,
    private tokenService: TokenService,
    private currentUserService: CurrentUserService,
    private router: Router,

  ) {
    this.authConfig = new AuthConfig(authConfig);
    {
      this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
      this.user = this.userSubject.asObservable();
      this.setupNavigationListener();
    }
  };
  // private startSessionTimeout() {
  //   this.sessionTimeout = setTimeout(() => {
  //     this.logout();
  //     alert('Your session has expired. Please log in again.');
  //   }, this.sessionTimeoutDuration);
  // }

  private setupNavigationListener() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (!this.isAuthenticated()) {
        history.pushState(null, '', location.href);
      }
    });
  }

  isLoggedIn(): boolean {
    
    const token = this.tokenService.getToken();
    return !!token && !!token.token;
    const user = localStorage.getItem('user');
    return user !== null;
  }
  isAuthenticated(): boolean {
    return !!this.tokenService.getToken();
  }
  private clearAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };
  BASE_URL = environment.apiUrl
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();


  public get userValue() {
    return this.userSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}api/Auth/login`, { email, password })
      .pipe(map(user => {
        if (!user.data.isUser3rdPartyVerified) {
          return user;
        }
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        const userId = user.data.id; 
        console.log("Logged in User ID:", userId);
        // this.startSessionTimeout();

        return user;
      }));
  }

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next(null);
    sessionStorage.removeItem('user');
    this.router.navigate(['/auth/sign-in']);
  }
  getUserId(): number {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user?.userId || null;
  }
  // authentication.service.ts

  // resetPassword(email: string, password: string, code: string) {
  //   const body = { email, password, code };

  //   return this.http.post<any>(`${environment.apiUrl}api/Auth/reset-password`, body);

  // }
  resetPassword(model: any): Observable<any> {
    return this.http.post(`${this.postUrl}/set-password`, model);
  }


  // login(username: string, password: string): Observable<any> {
  //   const body = {
  //     email: username,
  //     password: password
  //   };

  //   return this.http.post(this.authConfig.loginEndPoint, body, { headers: new HttpHeaders().set('bindErrorHandler', 'DISABLED') }).pipe(map((response: any) => {
  //     return this.handleResponse(response);
  //   }));
  // }


  // loginWith2fa(model: any): Observable<any> {
  //   return this.http.post(`${this.BASE_URL}api/account/sign-in-2fa`, model, { headers: new HttpHeaders().set('bindErrorHandler', 'DISABLED') }).pipe(map((response: any) => {
  //     return this.handleResponse(response);
  //   }));
  // }

  // googleLogin(model: any): Observable<any> {
  //   return this.http.post(`${this.BASE_URL}api/account/google-sign-in`, model, { headers: new HttpHeaders().set('bindErrorHandler', 'DISABLED') }).pipe(map((response: any) => {
  //     return this.handleResponse(response);
  //   }));
  // }


  // facebookLogin(model: any): Observable<any> {
  //   return this.http.post(`${this.BASE_URL}api/account/facebook-sign-in`, model, { headers: new HttpHeaders().set('bindErrorHandler', 'DISABLED') }).pipe(map((response: any) => {
  //     return this.handleResponse(response);
  //   }));
  // }

  // handleResponse(response: any): any {
  //   if (response && response.accessToken) {
  //     const token = response.accessToken;
  //     // const refreshToken = response.refresh_token;

  //     if (token) {
  //       this.currentUserService.clearCurrentUser();

  //       this.tokenService.setToken(token);
  //       // this.tokenService.setRefreshToken(refreshToken);
  //     }
  //     return response;
  //   }
  //   // if (response && response.access_token) {
  //   //   const token = response.access_token;
  //   //   const refreshToken = response.refresh_token;

  //   //   if (token) {
  //   //     this.currentUserService.clearCurrentUser();

  //   //     this.tokenService.setToken(token);
  //   //     this.tokenService.setRefreshToken(refreshToken);
  //   //   }
  //   //   return response;
  //   // }
  //   else if (response && response.scope && response.token_type) {
  //     return response;
  //   }
  //   else {
  //     return "invalid_response";
  //   }
  // }

  // refreshToken(): Observable<boolean> {
  //   if (this.isRefreshInProgress) {
  //     return new Observable(observer => {
  //       this.tokenRefreshed$.subscribe(() => {
  //         observer.next();
  //         observer.complete();
  //       });
  //     });
  //   }

  //   const grant_type = 'refresh_token';
  //   const client_id = 'client-spa';
  //   const client_secret = 'secret';
  //   this.isRefreshInProgress = true;

  //   const body = new HttpParams({ encoder: new GhQueryEncoder() })
  //     .set('grant_type', grant_type)
  //     .set('client_id', client_id)
  //     .set('client_secret', client_secret)
  //     .set('refresh_token', this.tokenService.getRefreshToken());

  //   const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

  //   return this.http.post(this.authConfig.loginEndPoint, body, { headers: header }).pipe(map((response: any) => {
  //     if (response && response.access_token) {
  //       const token = response.access_token;
  //       const refreshToken = response.refresh_token;

  //       this.tokenService.setToken(token);
  //       this.tokenService.setRefreshToken(refreshToken);
  //       this.isRefreshInProgress = false;
  //       return true;
  //     }

  //     this.logout();
  //   }),
  //     catchError(err => {
  //       this.isRefreshInProgress = false;
  //       this.logout();
  //       return of(err);
  //     })
  //   );
  // }

  // logout(): void {
  //   this.tokenService.removeTokens();

  //   let returnUrl = window.location.search.includes('returnUrl') ? window.location.pathname : window.location.pathname + window.location.search;
  //   this.router.navigate(['/auth/sign-in'],);
  // }

  // public collectFailedRequest(request: HttpRequest<any>, subject: Subject<any>): void {
  //   this.cachedFailedRequests.push({ request, subject });
  // }

  // public retryFailedRequests(): void {
  //   if (this.cachedFailedRequests.length === 0) {
  //     return;
  //   }

  //   const item = this.cachedFailedRequests.shift();
  //   const request = item!.request.clone();
  //   (request as any).isRepeated = true;
  //   this.http.request(request)
  //     .subscribe(response => {
  //       item!.subject.next(response);
  //       this.retryFailedRequests();
  //     },
  //       (error) => {
  //         item!.subject.error(error);
  //         this.retryFailedRequests();
  //       }
  //     );
  // }
}

class GhQueryEncoder extends HttpUrlEncodingCodec {
  override encodeKey(k: string): string {
    k = super.encodeKey(k);
    return k.replace(/\+/gi, '%2B');
  }
  override encodeValue(v: string): string {
    v = super.encodeKey(v);
    return v.replace(/\+/gi, '%2B');
  }
}
