import { Component } from '@angular/core';

@Component({
  selector: 'mvms-error-toast',
  template: `
    <div>
    </div>
  `
})
export class ErrorToastComponent {
  // public toast: Toast = {type: 'success'};

  constructor() {

  }
}
