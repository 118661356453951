<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="sidebar bg-dark text-white" [class.open]="isSidebarOpen">
    <div class="d-flex flex-column p-3">
      <!-- Logo Section -->
      <div class="logo mb-3 text-center">
        <a [routerLink]="['']" aria-label="Home">
          <i class="bi bi-house-door fs-2"></i>
        </a>
      </div>


      <!-- Pages Section -->
      <div class="px-3 py-2">
        <strong>MENUS</strong>
      </div>

      <ul class="nav flex-column bg-black py-4 px-3">
        <!-- Quick Access Menu -->
        <li class="nav-item" [class.active-li]="isActive('quickAccess')">
          <a class="text-white fw-bold d-flex justify-content-between align-items-center py-2"
            (click)="toggleSubmenu('quickAccess')">
            <img src="assets/images/dashboard/quick-access.svg" alt="quick icon" width="25px" />
            Quick Access
            <i class="bi" [ngClass]="{
                'bi-chevron-up': activeSubmenu === 'quickAccess',
                'bi-chevron-down': activeSubmenu !== 'quickAccess'
              }"></i>
          </a>
          <ul class="submenu" [class.show]="activeSubmenu === 'quickAccess'">
            <li class="nav-item" [class.active-li]="isActive('/dashboard')">
              <a class="nav-link" [routerLink]="['/dashboard']">
                <i class="bi bi-house-fill me-2 icon-orange"></i>Dashboard</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/topup')">
              <a class="nav-link" [routerLink]="['/dashboard/topup']">
                <i class="bi bi-cash-coin me-2 icon-orange"></i>Top up credits</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/buy-subscription')">
              <a class="nav-link" [routerLink]="['/dashboard/buy-subscription']">
                <i class="bi bi-bag-plus me-2 icon-orange"></i>Buy subscription</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/justEatSetting')">
              <a class="nav-link" [routerLink]="['/dashboard/justEatSetting']">
                <i class="bi bi-gear-fill me-2 icon-orange"></i>JustEat Setting</a>
            </li>
          </ul>
        </li>

        <!-- Shift Catcher Menu -->
        <li class="nav-item" [class.active-li]="isActive('shiftCatcher')">
          <a class="text-white fw-bold d-flex justify-content-between align-items-center py-2"
            (click)="toggleSubmenu('shiftCatcher')">
            <img src="assets/images/dashboard/shift-icon.svg" alt="shift icon" width="25px" />
            Shift Catcher
            <i class="bi" [ngClass]="{
                'bi-chevron-up': activeSubmenu === 'shiftCatcher',
                'bi-chevron-down': activeSubmenu !== 'shiftCatcher'
              }"></i>
          </a>
          <ul class="submenu" [class.show]="activeSubmenu === 'shiftCatcher'">
            <li class="nav-item" [class.active-li]="isActive('/dashboard/topUp-history')">
              <a class="nav-link" [routerLink]="['/dashboard/topUp-history']">
                <i class="bi bi-card-list me-2 icon-orange"></i>TopUp history</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/Subscription-history')">
              <a class="nav-link" [routerLink]="['/dashboard/Subscription-history']">
                <i class="bi bi-clock-history me-2 icon-orange"></i>Subscription history</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/shift-history')">
              <a class="nav-link" [routerLink]="['/dashboard/shift-history']">
                <i class="bi bi-calendar2-check me-2 icon-orange"></i>Shift history</a>
            </li>
          </ul>
        </li>

        <!-- Courier Detail Menu -->
        <li class="nav-item" [class.active-li]="isActive('courerDetail')">
          <a class="text-white fw-bold d-flex justify-content-between align-items-center py-2"
            (click)="toggleSubmenu('courerDetail')">
            <img src="assets/images/dashboard/courior-detail.svg" alt="courier icon" width="25px" />
            Courier Detail
            <i class="bi" [ngClass]="{
                'bi-chevron-up': activeSubmenu === 'courerDetail',
                'bi-chevron-down': activeSubmenu !== 'courerDetail'
              }"></i>
          </a>
          <ul class="submenu" [class.show]="activeSubmenu === 'courerDetail'">
            <li class="nav-item" [class.active-li]="isActive('/dashboard/schedule')">
              <a class="nav-link" [routerLink]="['/dashboard/schedule']">
                <i class="bi bi-clock me-2 icon-orange"></i>Time schedule</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/zones')">
              <a class="nav-link" [routerLink]="['/dashboard/zones']">
                <i class="bi bi-geo-fill me-2 icon-orange"></i>Zone picker</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/justEat-login')">
              <a class="nav-link" [routerLink]="['/dashboard/justEat-login']">
                <i class="bi bi-pencil-fill me-2 icon-orange"></i>Update JustEat Details</a>
            </li>
          </ul>
        </li>

        <!-- My Account Menu -->
        <li class="nav-item" [class.active-li]="isActive('account')">
          <a class="text-white fw-bold d-flex justify-content-between align-items-center py-2"
            (click)="toggleSubmenu('account')">
            <img src="assets/images/dashboard/my-acccount.svg" alt="account icon" width="25px" />
            My Account
            <i class="bi" [ngClass]="{
                'bi-chevron-up': activeSubmenu === 'account',
                'bi-chevron-down': activeSubmenu !== 'account'
              }"></i>
          </a>
          <ul class="submenu" [class.show]="activeSubmenu === 'account'">
            <li class="nav-item" [class.active-li]="isActive('/dashboard/change-password')">
              <a class="nav-link" [routerLink]="['/dashboard/change-password']">
                <i class="bi bi-key-fill me-2 icon-orange"></i>Change Password</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/changenumber')">
              <a class="nav-link" [routerLink]="['/dashboard/changenumber']">
                <i class="bi bi-phone-fill me-2 icon-orange"></i>Change Phone Number</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/contactUs')">
              <a class="nav-link" [routerLink]="['/dashboard/contactUs']">
                <i class="bi bi-envelope-fill me-2 icon-orange"></i>Contact Us</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/aboutUs')">
              <a class="nav-link" [routerLink]="['/dashboard/aboutUs']">
                <i class="bi bi-info-circle-fill me-2 icon-orange"></i>About Us</a>
            </li>
            <li class="nav-item" [class.active-li]="isActive('/dashboard/deleteProfile')">
              <a class="nav-link" [routerLink]="['/dashboard/deleteProfile']">
                <i class="bi bi-trash-fill me-2 icon-orange"></i>Delete Account</a>
            </li>
            <li class="float-start"><a class="nav-link" (click)="logout()">
                <i class="bi bi-box-arrow-right me-2 icon-orange"></i>Sign Out</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>