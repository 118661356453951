import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthConfig, IAuthConfig } from './auth.config';
import { TokenService } from './services/token.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuard } from './guard/auth.guard';
import { ErrorToastComponent } from './services/error-toast.component';
import { RouterModule } from '@angular/router';
import { JwtInterceptor } from './services/jwt.interceptor';

@NgModule({
  imports: [
    CommonModule, RouterModule
  ],

  declarations: [
    ErrorToastComponent
  ],
  exports: [
  ]
})
export class AuthModule {
  static forRoot(config?: IAuthConfig): ModuleWithProviders<any> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: config },
        TokenService, AuthenticationService, AuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true,
        },
      ]
    };
  }
}
