import { Inject, Injectable } from '@angular/core';

export interface IAuthConfig {
  globalHeaders?: Array<Object>;
  headerName?: string;
  headerPrefix?: string;
  noJwtError?: boolean;
  noTokenScheme?: boolean;
  loginEndPoint?: string;
  loginTokenName?: string;
  loginParams?: ILoginParams;
  guards?: {
    loggedInGuard: {
      redirectUrl: string;
    },
    loggedOutGuard: {
      redirectUrl: string;
    },
  };
}
export interface ILoginParams {
  [key: string]: string;
}
@Injectable({ providedIn: 'root' })
export class AuthConfig implements IAuthConfig {
  public headerName: string | any;
  public headerPrefix: string | any;
  public loginEndPoint: string | any;
  public guards?: {
    loggedInGuard: {
      redirectUrl: string;
    },
    loggedOutGuard: {
      redirectUrl: string;
    },
  };

  constructor(@Inject(Object) config: IAuthConfig = {}) {
    for (const option in config) {
      this[option] = config[option];
    }

    for (const option in AUTH_CONFIG_DEFAULTS)
      this[option] = config[option] != null ? config[option] : AUTH_CONFIG_DEFAULTS[option];
  }
}

export const AUTH_CONFIG_DEFAULTS: IAuthConfig = {
  headerName: 'Authorization',
  loginTokenName: 'access_token',
  headerPrefix: 'Bearer',
  noTokenScheme: false,
  noJwtError: false,
};
