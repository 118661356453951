import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { env } from 'process';

// Define the ServicePackage interface (you can replace this with your actual type definition)
export interface ServicePackage {
  getServicePackageDetails: any;
  servicePackageCategoryName: string;
  id: number;
  name: string;
  description: string;
  price: number;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  thirdPartyVerification(userPayload: any) {
    throw new Error('Method not implemented.');
  }
  currentUser: any;
  // Base URL for the API
  private postUrl = `${environment.apiUrl}api/Auth`;
  private apiUrl = `${environment.apiUrl}api/ScheduleDayTime`;
  private baseUrl = `${environment.apiUrl}api/ServicePackage`;
  private userInfo = `${environment.apiUrl}api/UserInfo`;
  private Topup = `${environment.apiUrl}api/Topup`;
  private Payment = `${environment.apiUrl}api/PaymentConfig`;
  private zones = `${environment.apiUrl}api/CourierUserZones`;
  private delete = `${environment.apiUrl}api/UserInfo`;
  private courier = `${environment.apiUrl}api/CourierBookShifts`;
  private justeat = `${environment.apiUrl}api/GrabberActivation`;
  private userInfoUrl = `${environment.apiUrl}api/UserInfo`;
  private userUrl = `${environment.apiUrl}api/Auth`;
  private ConfirmBookShifts = `${environment.apiUrl}api/ConfirmBookShifts`
  // private getShiftsHistory = `${environment.apiUrl}api/ConfirmBookShifts/ShiftsHistory`

  // private history = `${environment.apiUrl}api/Topup`;

  constructor(private http: HttpClient) { }

  // ######API for Scheduling############

  // subscribePackage(servicePackageId: number): Observable<any> {
  //   const url = `${this.apiUrl}/apiGetAllSchedules`;
  //   return this.http.post<any>(url, { servicePackageId });
  // }

  getAllPackages(): Observable<ServicePackage[]> {
    const url = `${this.baseUrl}/GetAllPackages`;
    return this.http.get<ServicePackage[]>(url).pipe(
      catchError(this.handleError)
    );
  }
  updateNumber(model: any): Observable<any> {
    return this.http.put<any>(`${this.userInfo}/PhoneNumber`, model);
  }
// Apply coupon to a package subscription
applyCoupon(model: any): Observable<any> {
  return this.http.post<any>(`${this.apiUrl}/apply-coupon`, model);
}


  getAllSchedules(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/GetAllSchedules`);
  }
  getTimeOptions(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/GetAll`);
  }
  resetPassword(model: any) {
    return this.http.post<any>(`${this.postUrl}/reset-password`, model);
  }
  getAll(scheduleData: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/GetAll`, scheduleData)
  }

  updateSchedule(model: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/Update`, model);
  }
  subscribedPackage(userId: number): Observable<any> {
    const url = `${this.baseUrl}/subscribedPackage/${userId}`;
    return this.http.get<any>(url)

  }
  BuySubscription(model: any): Observable<any> {
    const url = `${this.baseUrl}/subscribe`;
    return this.http.post(url, model);
  }

  submitContactForm(model: any): Observable<any> {
    return this.http.post<any>(`${this.userInfo}/contact-us`, model);
  }
  getUserById(): Observable<any> {
    return this.http.get<any>(`${this.userInfo}/GetUserDetail`)
  }

  addTopup(model: any): Observable<any> {
    return this.http.post<any>(`${this.Topup}/CreateHistory`, model);
  }
  getPaymentList(): Observable<any> {
    return this.http.get<any>(`${this.Payment}/PaymentConfigGetList`)
  }
  paymentHistory(): Observable<any> {
    return this.http.get<any>(`${this.Topup}/TotalAmount`)

  }
  UserHistory(): Observable<any> {
    return this.http.get<any>(`${this.Topup}/UserHistory`)

  }
  updataToggle(model: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/UpdateToggleStatus`, model, { responseType: 'text' });
  }
  getAllCourierUserZones(): Observable<any> {
    return this.http.get(`${this.zones}/GetAllCourierUserZones`)
  };
  updateCourierZone(model: any): Observable<any> {
    return this.http.put<any>(`${this.zones}/Update-CourierUserZones`, model);
  }
  getUserInfo(): Observable<any> {

    if (this.currentUser != null) {
      return of(this.currentUser)
    } else {
      return this.getUserById().pipe(
        tap(userInfo => this.currentUser = userInfo), // Cache the response
        catchError(this.handleError)
      );
    }

  };
  deleteUserInfo(userId: number): Observable<any> {
    return this.http.delete<any>(`${this.delete}/Delete-User/${userId}`);
  }
  uploadProof(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.Topup}/Trx-Src`, formData);
  }
  
  
  // getShiftsHistory(userId: number): Observable<any> {
  //   return this.http.get<any>(`${this.courier}/ShiftsHistory/${userId}`);
  // }
  public getShiftsHistory(model:any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/ConfirmBookShifts/ShiftsHistory`, model);
  }

  getJustEat(): Observable<any> {
    return this.http.get<any>(`${this.justeat}/get-GrabActivationData`);
  }
  updateJustEat(model: any): Observable<any> {
    return this.http.put<any>(`${this.justeat}/update-GrabActivationData`, model);
  }
  
reverifyZone(model: any):Observable<any> {
  return this.http.put<any>(`${this.zones}/Reverify-CourierUserZones`,
    model
  );
}
  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('An error occurred:', error.message);
    return throwError('Something went wrong; please try again later.');
  }

  
  // ########Third-party####################


  thirdPartyVarification(model: any, url: string): Observable<any> {
    const headers = new HttpHeaders({
      'app-token': '31983a5d-37b1-4390-bd1c-8184e855e5da',
     'Content-Type': 'application/json',
      'Cookie': '__cf_bm=lwYti1_2Vb8Sr4PKx7tbkwz7jIydHRQGCYl0LXPaFog-1724489200-1.0.1.1-71paa3ott.JHOw3MOd7EwQcOG2bPA0T0VO9ET1jyv3qSOaGuigvywNXAZdr2lC7Cqv7FFPJ6kaUimjVkQoWzvg' // Replace with actual cookie value
    });
  
    return this.http.post<any>(url, model, { headers });
  }
  ConfirmEmail(model: any): Observable<any> {
    return this.http.get<any>(`${this.userUrl}/confirm-email?code=${model.code}&&email=${model.email}`);
  };

  UserZoneDetail(id: any, url: string): Observable<any> {
    const headers = new HttpHeaders({
      'app-token': '31983a5d-37b1-4390-bd1c-8184e855e5da', 
      'user-token': '7d5ce401-2b7a-4c9d-9179-7e8714dc9734',
      'Cookie': '__cf_bm=WB48e76R2NqFuODULURaHpbpmCmPB0BwEdA0ULXuL_M-1724491010-1.0.1.1-kkOER4zN2k8R7obemV0whfsVqITVKarcQpi0Yq3ue.mtF59tY1iMr4ZPGxBeK6YglLxNsqvODWcwHlU5.jQhsQ' // Replace with actual cookie value
    });
  
    return this.http.get<any>(url, { headers });
  }

  UserDetail(id: any, url: string): Observable<any> {
    // Set the required headers
    const headers = new HttpHeaders({
      'user-token': '7d5ce401-2b7a-4c9d-9179-7e8714dc9734',
      'app-token': '31983a5d-37b1-4390-bd1c-8184e855e5da', 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cookie': '__cf_bm=lwYti1_2Vb8Sr4PKx7tbkwz7jIydHRQGCYl0LXPaFog-1724489200-1.0.1.1-71paa3ott.JHOw3MOd7EwQcOG2bPA0T0VO9ET1jyv3qSOaGuigvywNXAZdr2lC7Cqv7FFPJ6kaUimjVkQoWzvg' // Replace with actual cookie value
    });
  
    return this.http.get<any>(url, { headers });
  }
  UserInfo(model): Observable<any> {
    return this.http.post<any>(`${this.userInfoUrl}/create-CourierUser`, model);
  }
  getAllCourierAPIs(): Observable<any> {
    return this.http.get<any>(`${this.userInfoUrl}/GetAllCourierAPIs`);
  }
}
