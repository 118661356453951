import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  activeSubmenu: string;
  userName: string = '';
  user: any;
  totalTopupAmount: number = 0; // Define the totalTopupAmount property

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private dashboardService: DashboardService // Inject the dashboard service
  ) {}

  ngOnInit(): void {
    this.user = this.authService.userValue; // Get the user object from the AuthenticationService
    if (this.user && this.user.data && this.user.data.email) {
      this.userName = this.extractUserName(this.user.data.email);
    }

    // Fetch the total top-up amount from the service
    this.getTopupAmount();
  }

  toggleSidebar() {
    const mobileNav = document.getElementById('mobile-nav');
    const closebtn = document.getElementById('close-btn');
    if (mobileNav && closebtn) {
      mobileNav.classList.toggle('show-nav');
      closebtn.classList.toggle('closebtn');
      closebtn.classList.toggle('open-toggler');
    }
  }

  logout() {
    this.authService.logout();
    this.activeSubmenu = 'quickAccess'; // Reset to default submenu
    localStorage.setItem('activeSubmenu', this.activeSubmenu); // Store the default submenu
  }

  extractUserName(email: string): string {
    return email.split('@')[0];
  }

  // Fetch the top-up amount from the service
 getTopupAmount(): void {
  this.dashboardService.getUserInfo().subscribe(
    (data) => {
      this.totalTopupAmount = data.topupAmount || 0;
      this.checkIfLoadingComplete();
    },
    (error) => {
      this.checkIfLoadingComplete();
    }
  );
}
  checkIfLoadingComplete() {
    throw new Error('Method not implemented.');
  }
z
}
