import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { DashboardLayoutComponent } from './dashboard/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from './helpers/auth.guard';
import { ScheduleComponent } from './dashboard/schedule/schedule.component';
// import { ScheduleComponent } from './dashboard/schedule/schedule.component';
// import { ScheduleComponent } from './dashboard/schedule/schedule.component';
// import { AuthGuard } from './auth/guard/auth.guard';
// import { SignupComponent } from './account/signup/signup.component';
// import { SigninComponent } from './account/signin/signin.component';
// import * as path from 'path';
// import { RecoverPasswordComponent } from './account/recover-password/recover-password.component';





export const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: { title: 'Home', preload: true, delay: 0 }
  },
  
  // {
  //   path: 'sign-up',
  //   component: SignupComponent
  // },
  {
    path: 'auth',
    component: HomeLayoutComponent,
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    data: { title: 'Account', preload: true, delay: 0 }
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
